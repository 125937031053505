import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import { withTranslation } from "react-i18next";
import axios from 'axios';
import FormData from 'form-data';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReactPlayer from "react-player";

const BannerConfiguration = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    banner_media: [],
  });
  const [newBanner, setNewBanner] = useState(null);
  const [newBannerPreview, setNewBannerPreview] = useState(null);
  const [updateMedia, setUpdateMedia] = useState(null);
  const [modal, setModal] = useState(false);
  const fileInputRef = useRef(null);
  const newBannerInputRef = useRef(null);

  const toggleModal = () => setModal(!modal);

  const fetchSettings = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.data;
      setSettings({
        ...settings,
        banner_media: data.banner_media || [],
      });
    } catch (error) {
      console.error("There was an error fetching the settings:", error);
    }
  };

  const handleUpdate = (media) => {
    setUpdateMedia(media);
    fileInputRef.current.click();
  };

  const handleUpdateFileChange = async (e) => {
    setLoading(true);
    const newFile = e.target.files[0];

    if (!newFile) {
      setLoading(false);
      return;
    }

    let data = new FormData();
    data.append('file', newFile);

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting/banner/${updateMedia}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response.status) {
        toastr.success('Banner Updated Successfully!', 'Success');
        fetchSettings(); // Re-fetch settings after successful update
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error updating the banner:", error);
      setLoading(false);
    }
  };

  const handleDelete = async (media) => {
    setLoading(true);
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting/banner/${media}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status) {
        toastr.success('Banner Deleted Successfully!', 'Success');
        fetchSettings(); // Re-fetch settings after successful deletion
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error deleting the banner:", error);
      setLoading(false);
    }
  };

  const handleNewBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewBanner(file);
      setNewBannerPreview(URL.createObjectURL(file));
    }
  };

  const handleAddBanner = async () => {
    if (!newBanner) return;
    setLoading(true);

    let data = new FormData();
    data.append('file', newBanner);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting/banner`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response.status) {
        toastr.success('Banner Added Successfully!', 'Success');
        setNewBanner(null);
        setNewBannerPreview(null);
        fetchSettings(); // Re-fetch settings after successful submission
        toggleModal(); // Close modal after adding banner
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error adding the banner:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Dashboard | Quippy - React Admin & Dashboard Template";
    fetchSettings();
  }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container fluid={true}>
        <Row>
          <Col lg="12">
            <Button color="success" className="mb-3 float-end" onClick={toggleModal} disabled={loading}>
              Add Banner
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="12">
            <Row>
              {settings.banner_media && settings.banner_media.length > 0 ? (
                settings.banner_media.map((media, index) => {
                  const isImage = media.match(/\.(jpeg|jpg|gif|png)$/) != null;
                  const isVideo = media.match(/\.(mp4|webm|ogg)$/) != null;
                  const mediaUrl = `${process.env.REACT_APP_BASE_URL}/api/banner/${media}`;

                  return (
                    <Col md="4" key={index} className="mb-4">
                      <Card>
                        <CardBody>
                          <div className="banner-container text-center position-relative" style={{ height: '200px', overflow: 'hidden' }}>
                            {isImage && <img src={mediaUrl} alt={`Banner ${index}`} className="img-fluid" style={{ height: '100%', objectFit: 'cover' }} />}
                            {isVideo && (
                              <ReactPlayer
                                url={mediaUrl}
                                playing={false}
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            )}
                            <div className="banner-actions position-absolute top-0 end-0 m-2">
                              <Button color="warning" size="sm" onClick={() => handleUpdate(media)} disabled={loading}>
                                {loading ? <><i className="fa fa-spinner fa-spin"></i> Updating...</> : 'Edit'}
                              </Button>
                              <Button color="danger" size="sm" onClick={() => handleDelete(media)} disabled={loading} className="ms-2">
                                {loading ? <><i className="fa fa-spinner fa-spin"></i> Deleting...</> : 'Delete'}
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col>
                  <p>No banners available. Please add a banner.</p>
                </Col>
              )}
            </Row>
            
            <input
              type="file"
              id="update-file-input"
              style={{ display: 'none' }}
              onChange={handleUpdateFileChange}
              ref={fileInputRef}
            />
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Banner</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="new-banner-input">Select Image or Video</Label>
            <Input
              type="file"
              id="new-banner-input"
              onChange={handleNewBannerChange}
              innerRef={newBannerInputRef}
            />
            {newBannerPreview && (
              <div className="text-center mt-3">
                <div className="banner-preview">
                  {newBanner.type.startsWith('image/') ? (
                    <img src={newBannerPreview} alt="New Banner" className="img-fluid" />
                  ) : (
                    <ReactPlayer url={newBannerPreview} controls={true} width="100%" height="auto" />
                  )}
                </div>
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddBanner} disabled={loading}>
            {loading ? <><i className="fa fa-spinner fa-spin"></i> Uploading...</> : 'Upload Banner'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withTranslation()(BannerConfiguration);

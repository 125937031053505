import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../components/Common/DeleteModal";
import SweetAlert2 from 'react-sweetalert2';
import ReactPlayer from "react-player";

const QuizManager = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [quizData, setQuizData] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/quiz`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.data;
      if(data){
        setQuizData(data);
        setTitle(data?.title);
        setText(data?.text);
        if(response.status === 201){
          toastr.error(response.data.message, 'Error');
        } 
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching the quiz data:", error);
      setLoading(false);
    }
  };

  const handleAddRowNested = () => {
    setrows1([...rows1, { id: rows1.length + 1, question: "" }]);
  };

  const handleRemoveRow = (id) => {
    if (id !== 1) {
      setrows1(rows1.filter((x) => x.id !== id));
    }
  };

  const handleInputChange = (e, field) => {
    if (field === "file") {
      setFile(e.target.files[0]);
    } else if (field === "title") {
      setTitle(e.target.value);
    } else if (field === "text") {
      setText(e.target.value);
    } else {
      const { name, value } = e.target;
      const newRows = rows1.map((row) => {
        if (row.id === parseInt(name)) {
          return { ...row, question: value };
        }
        return row;
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!title) {
      errors.title = "Title is required";
    }
    if (!text) {
      errors.text = "Text is required";
    }
    if (!file && !quizData) {
      errors.file = "File is required";
    }
    setErrors(errors);
    setLoading(false);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("text", text);
    if(file){
      formData.append("file", file);
    }
    
    try {
      let config = {};
      
      if(!quizData) {
          config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/api/v1/quiz`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
            },
            data: formData,
          };
      } else {
        config = {
          method: "PATCH",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_URL}/api/v1/quiz/${quizData?._id}`,
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
          },
          data: formData,
        };
      }
      try {
        const response = await axios.request(config);
        if(response.status === 200){
          toastr.success(response.data.message, 'Success');
        }
        if(response.status === 201){
          if(response.statusText === 'Created'){
            toastr.success(response.data.message, 'Success');
          }else{
            toastr.error(response.data.message, 'Error');
          }
        } 
        fetchData();
        setLoading(false);
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        setLoading(false);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setLoading(false);
    }
  };

  const onClickDelete = () => {
    setDeleteModal(true);
  };

  const handleDeleteCustomer = async () => {

    if (quizData && quizData?._id) {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/quiz/${quizData?._id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
      try {
        const response = await axios.request(config);
        console.log("response > > > >",response);
        setSwalProps({
          icon: "success",
          show: true,
          title: 'Deleted Successfully.!!',
          text: '',
          confirmButtonText: 'OK', // Customize button text
          showCancelButton: false, // Hide cancel button
        });
        console.log(response);
        if(response.status === 200){
          setTitle("");
          setText("");
          setQuizData("");
          setFile(null);
          setDeleteModal(false);
        }
      } catch (error) {
        console.error("There was an error deleting the quiz:", error);
      }
    }
  };

  const renderMedia = (file) => {
    if (!file) return null;
    const fileExtension = file.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isVideo = ['mp4', 'avi', 'mov', 'wmv'].includes(fileExtension);

    if (isImage) {
      return (
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}/quiz/${file}`}
          alt="Quiz"
          style={{ maxWidth: '20%', height: 'auto', paddingTop: '10px' }}
        />
      );
    }

    if (isVideo) {
      return (
        <ReactPlayer
          url={`${process.env.REACT_APP_MEDIA_URL}/quiz/${file}`}
          playing={false}
          controls={true}
          width="20%"
          height='auto'
          style={{ maxWidth: '20%', height: 'auto', paddingTop: '10px' }}
        />
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <SweetAlert2 {...swalProps} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Quiz Manager" />

          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h6 className="mb-4 card-title">Quiz Manager</h6>

                    { quizData?._id && (
                      <Button type="button" color="danger" onClick={() => {
                          onClickDelete();
                        }}>
                        Delete
                      </Button>
                    )}
                  </div>
                  <Form className="outer-repeater" onSubmit={handleSubmit}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <div className="mb-3">
                          <Label htmlFor="title">Title :</Label>
                          <Input
                            type="text"
                            id="title"
                            className="form-control"
                            placeholder="Enter Title"
                            value={title}
                            onChange={(e) => handleInputChange(e, "title")}
                          />
                          {errors.title && <div className="text-danger">{errors.title}</div>}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="file">Upload File :</Label>
                          <Input
                            type="file"
                            name="file"
                            onChange={(e) => handleInputChange(e, "file")}
                          />
                          {errors.file && <div className="text-danger">{errors.file}</div>}
                          {renderMedia(quizData?.file)}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="text">Text :</Label>
                          <Input
                            type="textarea"
                            id="text"
                            className="form-control"
                            rows="3"
                            placeholder="Enter Text"
                            value={text}
                            onChange={(e) => handleInputChange(e, "text")}
                          />
                          {errors.text && <div className="text-danger">{errors.text}</div>}
                        </div>
                        
                        <Button type="submit" color="primary" disabled={loading} > 
                          {loading ? <><i className="fa fa-spinner fa-spin"></i> Loading...</> : (quizData?._id) ? 'Update' : 'Submit' }
                        </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

QuizManager.propTypes = {
  customers: PropTypes.array,
};

export default QuizManager;

import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  Collapse,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getChartsData as onGetChartsData } from "../../store/actions";
import axios from 'axios';
import FormData from 'form-data';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Configuration = ({ t }) => {
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { chartsData } = useSelector((state) => ({
    chartsData: state.Dashboard.chartsData,
  }));

  const [settings, setSettings] = useState({
    languages: [],
    message: "",
    welcome_message: "",
    radius: [],
    availability: [],
    price: [],
    experience: [],
    education_degrees: [],
    education_tags: [],
    contact_detail: {
      address: "",
      email: "",
      phone_number: "",
    },
    social_media_links: {
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
      linkedin: "",
    },
    logo: null,
    emoji: null
  });

  const [newLanguage, setNewLanguage] = useState("");
  const [newRadius, setNewRadius] = useState("");
  const [newAvailability, setNewAvailability] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newExperience, setNewExperience] = useState("");
  // const [newEducationDegree, setNewEducationDegree] = useState("");
  // const [newEducationTag, setNewEducationTag] = useState("");
  const [errors, setErrors] = useState({});
  const [editorKey, setEditorKey] = useState(0);

  const [emoji, setEmoji] = useState(null);

  useEffect(() => {
    // Force the editors to re-render when settings change
    setEditorKey(prevKey => prevKey + 1);
  }, [settings.welcome_message, settings.message]);

  const handleEditorChange = (field) => (event, editor) => {
    const data = editor.getData();
    setSettings(prev => ({ ...prev, [field]: data }));
  };
  
  const handleTagDelete = (i, type) => {
    const newTags = settings[type].filter((tag, index) => index !== i);
    setSettings({ ...settings, [type]: newTags });
  };

  const handleTagAddition = (type, newTag) => {
    if (newTag && !settings[type].includes(newTag)) {
      setSettings({ ...settings, [type]: [...settings[type], newTag] });
      if (type === "languages") setNewLanguage("");
      else if (type === "radius") setNewRadius("");
      else if (type === "availability") setNewAvailability("");
      else if (type === "price") setNewPrice("");
      else if (type === "experience") setNewExperience("");
      else if (type === "education_degrees") setNewEducationDegree("");
      else if (type === "education_tags") setNewEducationTag("");
    }
  };

  const handleInputChange = (e, type) => {
    const { value, name } = e.target;
    if (type === "logo") {
      setSettings({ ...settings, logo: e.target.files[0] });
    } else if(type === "emoji"){
      //setSettings({ ...settings, emoji: e.target.files[0] });
      setEmoji(e.target.files[0]);
    } else if (type === "contact_detail" || type === "social_media_links") {
      setSettings({ ...settings, [type]: { ...settings[type], [name]: value } });
    } else {
      if (type === "language") setNewLanguage(value);
      else if (type === "radius") setNewRadius(value);
      else if (type === "availability") setNewAvailability(value);
      else if (type === "price") setNewPrice(value);
      else if (type === "experience") setNewExperience(value);
      else if (type === "education_degree") setNewEducationDegree(value);
      else if (type === "education") setNewEducationTag(value);
      else setSettings({ ...settings, [type]: value });
    }
  };

  const handleInputKeyPress = (e, type) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleTagAddition(type, e.target.value);
    }
  };

  const validate = () => {
    let formErrors = {};
    if (!settings.languages.length) formErrors.languages = "Languages are required";
    if (!settings.message.trim()) formErrors.message = "Message is required";
    if (!settings.welcome_message.trim()) formErrors.welcome_message = "Welcome Message is required";
    if (!settings.radius.length) formErrors.radius = "Radius is required";
    if (!settings.availability.length) formErrors.availability = "Availability is required";
    if (!settings.price.length) formErrors.price = "Price range is required";
    if (!settings.experience.length) formErrors.experience = "Experience is required";
    if (!settings.education_degrees.length) formErrors.education_degrees = "Education degrees are required";
    if (!settings.education_tags.length) formErrors.education_tags = "Education tags are required";
    if (!settings.contact_detail.address) formErrors.contact_detail_address = "Address is required";
    if (!settings.contact_detail.email) formErrors.contact_detail_email = "Email is required";
    if (!settings.contact_detail.phone_number) formErrors.contact_detail_phone_number = "Phone number is required";
    if (!settings.social_media_links.facebook) formErrors.social_media_facebook = "Facebook URL is required";
    if (!settings.social_media_links.instagram) formErrors.social_media_instagram = "Instagram URL is required";
    if (!settings.social_media_links.twitter) formErrors.social_media_twitter = "Twitter URL is required";
    if (!settings.social_media_links.youtube) formErrors.social_media_youtube = "YouTube URL is required";
    if (!settings.social_media_links.linkedin) formErrors.social_media_linkedin = "LinkedIn URL is required";
    if (!settings.logo) formErrors.logo = "Logo is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleEmojiDelete = async (emoji) => {
    let config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting/emoji/${emoji}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      if(response.status){
        toastr.success('Emoji Deleted Successfullly!', 'Success');
        setSettings(prevSettings => ({
            ...prevSettings,
            emoji: null
        }));
        setEmoji(null);
      } 
      setLoading(false);
    } catch (error) {
      console.error("There was an error while uploading emoji:", error);
      setLoading(false);
    }
  }
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate()) {
      let data = new FormData();
      if(settings.logo && typeof settings?.logo != 'string'){
        data.append('logo', settings.logo);
      }
      if(emoji && typeof emoji != 'string'){
        // let emojiUpload = new FormData();
        // emojiUpload.append('logo', emoji);

        const formData = new FormData();
        formData.append('emoji', emoji);

        let config = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting/emoji`,
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
            'Content-Type': 'multipart/form-data',
          },
          data : formData
        };

        try {
          const response = await axios.request(config);
          if(response.status){
            toastr.success('Emoji uploaded Successfullly!', 'Success');
            setSettings(prevSettings => ({
                ...prevSettings,
                emoji: response.data.data.emoji
            }));
          } 
          setLoading(false);
        } catch (error) {
          console.error("There was an error while uploading emoji:", error);
          setLoading(false);
        }

      }
      settings.languages.forEach(language => data.append('languages', language));
      data.append('message', settings.message);
      data.append('welcome_message', settings.welcome_message);
      
      settings.radius.forEach(radius => data.append('radius', radius));
      settings.availability.forEach(availability => data.append('availability', availability));
      settings.price.forEach(price => data.append('price', price));
      settings.experience.forEach(experience => data.append('experience', experience));
      settings.education_degrees.forEach(degree => data.append('education_degrees', degree));
      settings.education_tags.forEach(tag => data.append('education_tags', tag));
      data.append('contact_detail', JSON.stringify(settings.contact_detail));
      data.append('social_media_links', JSON.stringify(settings.social_media_links));

      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        if(response.status){
          toastr.success('Setting Configured Successfullly!', 'Success');
        } 
        setLoading(false);
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const fetchSettings = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/setting`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.data;
      setSettings({
        ...settings,
        languages: data.languages || [],
        message: data.message || "",
        welcome_message: data.welcome_message || "",
        radius: data.radius || [],
        availability: data.availability || [],
        price: data.price || [],
        experience: data.experience || [],
        education_degrees: data.education_degrees || [],
        education_tags: data.education_tags || [],
        contact_detail: data.contact_detail || {
          address: "",
          email: "",
          phone_number: "",
        },
        social_media_links: data.social_media_links || {
          facebook: "",
          instagram: "",
          twitter: "",
          youtube: "",
          linkedin: "",
        },
        logo: data.logo || null,
        emoji: data.emoji || null
      });
    } catch (error) {
      console.error("There was an error fetching the settings:", error);
    }
  };

  useEffect(() => {
    document.title = "Dashboard | Quippy  - React Admin & Dashboard Template";
    fetchSettings();
  }, [dispatch]);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12">
            <Form onSubmit={handleSubmit}>
            <Col>
              <p className="card-title-desc">
                <h4><strong>Configure Settings</strong></h4>
              </p>

              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !col1 }
                      )}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer" }}
                    >
                      Main Configuration
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                    <Card>
                      <CardBody>
                        {/* <h4 className="card-title mb-4">Main Configuration</h4> */}
                        <FormGroup className="mb-3">
                          <Label className="form-label">Logo</Label>
                          <Input
                            type="file"
                            name="logo"
                            onChange={(e) => handleInputChange(e, "logo")}
                            invalid={!!errors.logo}
                          />
                          {errors.logo && (
                            <FormFeedback>
                              {errors.logo}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        {settings?.logo && typeof settings.logo === 'string' && (
                          <div className="mt-2">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/api/logo/${settings.logo}`}
                              alt="Logo Preview"
                              style={{ maxWidth: '20%', height: 'auto' }}
                            />
                          </div>
                        )}
                        {settings.logo && typeof settings.logo !== 'string' && (
                          <div className="mt-2">
                            <img
                              src={URL.createObjectURL(settings.logo)}
                              alt="Logo Preview"
                              style={{ maxWidth: '20%', height: 'auto' }}
                            />
                          </div>
                        )}
                        <FormGroup className="mb-3 pt-5">
                          <Label className="form-label">Languages</Label>
                          <Input
                            type="text"
                            value={newLanguage}
                            onChange={(e) => handleInputChange(e, "language")}
                            onKeyPress={(e) => handleInputKeyPress(e, "languages")}
                            invalid={!!errors.languages}
                            placeholder="Enter Language and press enter"
                          />
                          {errors.languages && (
                            <FormFeedback>
                              {errors.languages}
                            </FormFeedback>
                          )}
                          <div className="mt-2">
                            {settings.languages.map((language, index) => (
                              <Button
                                color="danger"
                                size="sm"
                                className="me-1"
                                key={index}
                                onClick={() => handleTagDelete(index, "languages")}
                              >
                                {language} &times;
                              </Button>
                            ))}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Welcome Message</Label>
                          <CKEditor
                            key="welcome_message"
                            editor={ClassicEditor}
                            data={settings.welcome_message}
                            onChange={handleEditorChange('welcome_message')}
                          />
                          {errors.welcome_message && (
                            <FormFeedback className="d-block">
                              {errors.welcome_message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Thank You Emoji
                          </Label>
                          <Input
                            type="file"
                            name="emoji"
                            onChange={(e) => handleInputChange(e, "emoji")}
                          />
                          
                          { !emoji && settings.emoji && typeof settings.emoji === 'string' && (
                            <div className="mt-2">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/api/emoji/${settings.emoji}`}
                                alt="emoji Preview"
                                style={{ maxWidth: '20%', height: 'auto' }}
                              />
                              <div style={{ marginTop: '4px' }}>
                                <Button
                                  color="danger"
                                  size="md"
                                  className="me-1"
                                  onClick={() => handleEmojiDelete(settings.emoji)}
                                >
                                  Delete Emoji
                                </Button>
                              </div>
                            </div>
                          )}
                          {emoji && typeof emoji !== 'string' && (
                            <div className="mt-2">
                              <img
                                src={URL.createObjectURL(emoji)}
                                alt="emoji Preview"
                                style={{ maxWidth: '20%', height: 'auto' }}
                              />
                              { settings.emoji && (
                                <div style={{ marginTop: '4px' }}>
                                  <Button
                                    color="danger"
                                    size="md"
                                    className="me-1"
                                    onClick={() => handleEmojiDelete(settings.emoji)}
                                  >
                                    Delete Emoji
                                  </Button>
                                </div>
                              ) }
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Thank You Message</Label>
                          <CKEditor
                            key="thankyou_message"
                            editor={ClassicEditor}
                            data={settings.message}
                            onChange={handleEditorChange('message')}
                          />
                          {errors.message && (
                            <FormFeedback className="d-block">
                              {errors.message}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Radius</Label>
                          <Input
                            type="text"
                            value={newRadius}
                            onChange={(e) => handleInputChange(e, "radius")}
                            onKeyPress={(e) => handleInputKeyPress(e, "radius")}
                            invalid={!!errors.radius}
                            placeholder="Enter Radius and press enter"
                          />
                          {errors.radius && (
                            <FormFeedback>
                              {errors.radius}
                            </FormFeedback>
                          )}
                          <div className="mt-2">
                            {settings.radius.map((radius, index) => (
                              <Button
                                color="danger"
                                size="sm"
                                className="me-1"
                                key={index}
                                onClick={() => handleTagDelete(index, "radius")}
                              >
                                {radius} &times;
                              </Button>
                            ))}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Availability</Label>
                          <Input
                            type="text"
                            value={newAvailability}
                            onChange={(e) => handleInputChange(e, "availability")}
                            onKeyPress={(e) => handleInputKeyPress(e, "availability")}
                            invalid={!!errors.availability}
                            placeholder="Enter Availability and press enter"
                          />
                          {errors.availability && (
                            <FormFeedback>
                              {errors.availability}
                            </FormFeedback>
                          )}
                          <div className="mt-2">
                            {settings.availability.map((availability, index) => (
                              <Button
                                color="danger"
                                size="sm"
                                className="me-1"
                                key={index}
                                onClick={() => handleTagDelete(index, "availability")}
                              >
                                {availability} &times;
                              </Button>
                            ))}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Price Range Per Hour(USD)</Label>
                          <Input
                            type="text"
                            value={newPrice}
                            onChange={(e) => handleInputChange(e, "price")}
                            onKeyPress={(e) => handleInputKeyPress(e, "price")}
                            invalid={!!errors.price}
                            placeholder="Enter Price Range and press enter"
                          />
                          {errors.price && (
                            <FormFeedback>
                              {errors.price}
                            </FormFeedback>
                          )}
                          <div className="mt-2">
                            {settings.price.map((price, index) => (
                              <Button
                                color="danger"
                                size="sm"
                                className="me-1"
                                key={index}
                                onClick={() => handleTagDelete(index, "price")}
                              >
                                {price} &times;
                              </Button>
                            ))}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Experience</Label>
                          <Input
                            type="text"
                            value={newExperience}
                            onChange={(e) => handleInputChange(e, "experience")}
                            onKeyPress={(e) => handleInputKeyPress(e, "experience")}
                            invalid={!!errors.experience}
                            placeholder="Enter Experience and press enter"
                          />
                          {errors.experience && (
                            <FormFeedback>
                              {errors.experience}
                            </FormFeedback>
                          )}
                          <div className="mt-2">
                            {settings.experience.map((experience, index) => (
                              <Button
                                color="danger"
                                size="sm"
                                className="me-1"
                                key={index}
                                onClick={() => handleTagDelete(index, "experience")}
                              >
                                {experience} &times;
                              </Button>
                            ))}
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Card>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !col2 }
                      )}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer" }}
                    >
                      Contact Details
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Card className="mt-4">
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Label className="form-label">Address</Label>
                            <Input
                              type="text"
                              name="address"
                              value={settings.contact_detail.address}
                              onChange={(e) => handleInputChange(e, "contact_detail")}
                              invalid={!!errors.contact_detail_address}
                            />
                            {errors.contact_detail_address && (
                              <FormFeedback>
                                {errors.contact_detail_address}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              type="email"
                              name="email"
                              value={settings.contact_detail.email}
                              onChange={(e) => handleInputChange(e, "contact_detail")}
                              invalid={!!errors.contact_detail_email}
                            />
                            {errors.contact_detail_email && (
                              <FormFeedback>
                                {errors.contact_detail_email}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <Input
                              type="text"
                              name="phone_number"
                              value={settings.contact_detail.phone_number}
                              onChange={(e) => handleInputChange(e, "contact_detail")}
                              invalid={!!errors.contact_detail_phone_number}
                            />
                            {errors.contact_detail_phone_number && (
                              <FormFeedback>
                                {errors.contact_detail_phone_number}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !col3 }
                      )}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer" }}
                    >
                      Social Media Links
                    </button>
                  </h2>
                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                    <Card className="mt-4">
                      <CardBody>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Facebook</Label>
                          <Input
                            type="text"
                            name="facebook"
                            value={settings.social_media_links.facebook}
                            onChange={(e) => handleInputChange(e, "social_media_links")}
                            invalid={!!errors.social_media_facebook}
                          />
                          {errors.social_media_facebook && (
                            <FormFeedback>
                              {errors.social_media_facebook}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Instagram</Label>
                          <Input
                            type="text"
                            name="instagram"
                            value={settings.social_media_links.instagram}
                            onChange={(e) => handleInputChange(e, "social_media_links")}
                            invalid={!!errors.social_media_instagram}
                          />
                          {errors.social_media_instagram && (
                            <FormFeedback>
                              {errors.social_media_instagram}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Twitter</Label>
                          <Input
                            type="text"
                            name="twitter"
                            value={settings.social_media_links.twitter}
                            onChange={(e) => handleInputChange(e, "social_media_links")}
                            invalid={!!errors.social_media_twitter}
                          />
                          {errors.social_media_twitter && (
                            <FormFeedback>
                              {errors.social_media_twitter}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">YouTube</Label>
                          <Input
                            type="text"
                            name="youtube"
                            value={settings.social_media_links.youtube}
                            onChange={(e) => handleInputChange(e, "social_media_links")}
                            invalid={!!errors.social_media_youtube}
                          />
                          {errors.social_media_youtube && (
                            <FormFeedback>
                              {errors.social_media_youtube}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="form-label">LinkedIn</Label>
                          <Input
                            type="text"
                            name="linkedin"
                            value={settings.social_media_links.linkedin}
                            onChange={(e) => handleInputChange(e, "social_media_links")}
                            invalid={!!errors.social_media_linkedin}
                          />
                          {errors.social_media_linkedin && (
                            <FormFeedback>
                              {errors.social_media_linkedin}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </CardBody>
                    </Card>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>

              <div className="text-end m-4">
                <Button color="primary" type="submit" disabled={loading}>
                {loading ? <><i className="fa fa-spinner fa-spin"></i> Loading...</> : 'Save Changes'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(Configuration);

import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from 'axios';
// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';

// Column
import {
  Isverified,
  UserName,
  PhoneEmail,
  Address,
  Rating,
  RequestStatus,
  WalletBalances,
  JoiningDate,
} from '../Ecommerce/EcommerceCustomers/EcommerceCustCol';
import SweetAlert2 from 'react-sweetalert2';

const EcommerceCustomers = () => {
  const [swalProps, setSwalProps] = useState({});
  // meta title
  document.title = "Customers | Quippy  - React Admin & Dashboard Template";

  const [modal, setModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: (customer && customer.username) || '',
      phone: (customer && customer.phone) || '',
      email: (customer && customer.email) || '',
      address: (customer && customer.address) || '',
      rating: (customer && customer.rating) || '',
      walletBalance: (customer && customer.walletBalance) || '',
      joiningDate: (customer && customer.joiningDate) || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),
      rating: Yup.string().matches(
        /\b([0-9]|10)\b/,
        "Please Enter Valid Rating"
      ).required("Please Enter Your Rating"),
      walletBalance: Yup.string().required("Please Enter Your Wallet Balance"),
      joiningDate: Yup.string().required("Please Enter Your Joining Date"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedCustomers = customers.map((cust) =>
          cust.id === customer.id ? { ...customer, ...values } : cust
        );
        setCustomers(updatedCustomers);
      } else {
        const newCustomer = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          ...values,
        };
        setCustomers([...customers, newCustomer]);
      }
      toggle();
      validation.resetForm();
    },
  });

  const handleCustomerClick = (customer) => {
    setCustomer(customer);
    setIsEdit(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   //Cell: () => <input type="checkbox" className="form-check-input" />,
      //   Cell: ({ row }) => row.index + 1, // Displaying 1-based index
      // },
      {
        Header: 'Title',
        accessor: 'title',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Text',
        accessor: 'text',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      }
    ],
    []
  );

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      setCustomer(null);
    }
  };

  const onClickDelete = (customer) => {
    setCustomer(customer);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = async () => {

    if (customer && customer._id) {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/teacher/${customer._id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
      try {
        const response = await axios.request(config);
        console.log("response > > > >",response);
        setSwalProps({
          icon: "success",
          show: true,
          title: 'Deleted Successfully.!!',
          text: '',
          confirmButtonText: 'OK', // Customize button text
          showCancelButton: false, // Hide cancel button
        });
        if(response.status == 200){
          const updatedCustomers = customers.filter(cust => cust._id !== customer._id);
          setCustomers(updatedCustomers);
          setDeleteModal(false);
        }
      } catch (error) {
        console.error("There was an error fetching the settings:", error);
      }
    }
  };

  useEffect(() => {

    // Assuming fetchQuizData is a function that fetches customer data from an API
    const fetchQuizData = async () => {
      //const data = await onGetCustomers();
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_FRONT_URL}/quiz`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
  
      try {
        const response = await axios.request(config);
        const data = response.data;
        console.log(data);
        setCustomers(data);
      } catch (error) {
        console.error("There was an error fetching the settings:", error);
      }
    };
    fetchQuizData();
  }, []);

  const handleCustomerClicks = () => {
    setCustomer(null);
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <SweetAlert2 {...swalProps} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Supplemental Questions" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={false}
                    isAddCustList={false}
                    handleCustomerClick={handleCustomerClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {isEdit ? "Edit Customer" : "Add Customer"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">UserName</Label>
                              <Input
                                name="username"
                                type="text"
                                placeholder="Insert User Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username && validation.errors.username ? true : false
                                }
                              />
                              {validation.touched.username && validation.errors.username ? (
                                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Phone No</Label>
                              <Input
                                name="phone"
                                type="text"
                                placeholder="Insert Phone No"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                              />
                              {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Email Id</Label>
                              <Input
                                name="email"
                                type="email"
                                placeholder="Insert Email Id"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Address</Label>
                              <Input
                                name="address"
                                type="textarea"
                                placeholder="Insert Address"
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address && validation.errors.address ? true : false
                                }
                              />
                              {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Rating</Label>
                              <Input
                                name="rating"
                                type="text"
                                placeholder="Insert Rating"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.rating || ""}
                                invalid={
                                  validation.touched.rating && validation.errors.rating ? true : false
                                }
                              />
                              {validation.touched.rating && validation.errors.rating ? (
                                <FormFeedback type="invalid">{validation.errors.rating}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Wallet Balance</Label>
                              <Input
                                name="walletBalance"
                                type="text"
                                placeholder="Insert Wallet Balance"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.walletBalance || ""}
                                invalid={
                                  validation.touched.walletBalance && validation.errors.walletBalance ? true : false
                                }
                              />
                              {validation.touched.walletBalance && validation.errors.walletBalance ? (
                                <FormFeedback type="invalid">{validation.errors.walletBalance}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Joining Date</Label>
                              <Input
                                name="joiningDate"
                                type="date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.joiningDate || ""}
                                invalid={
                                  validation.touched.joiningDate && validation.errors.joiningDate ? true : false
                                }
                              />
                              {validation.touched.joiningDate && validation.errors.joiningDate ? (
                                <FormFeedback type="invalid">{validation.errors.joiningDate}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
};

export default EcommerceCustomers;

import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from 'axios';
// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import TableContainer from '../../components/Common/TableContainer';

// Column
import {
  Isverified,
  UserName,
  PhoneEmail,
  Address,
  Rating,
  RequestStatus,
  WalletBalances,
  JoiningDate,
} from '../Ecommerce/EcommerceCustomers/EcommerceCustCol';
import SweetAlert2 from 'react-sweetalert2';

const EcommerceCustomers = () => {
  const [swalProps, setSwalProps] = useState({});
  // meta title
  document.title = "Customers | Quippy  - React Admin & Dashboard Template";

  const [modal, setModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: (customer && customer.username) || '',
      phone: (customer && customer.phone) || '',
      email: (customer && customer.email) || '',
      address: (customer && customer.address) || '',
      rating: (customer && customer.rating) || '',
      walletBalance: (customer && customer.walletBalance) || '',
      joiningDate: (customer && customer.joiningDate) || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),
      rating: Yup.string().matches(
        /\b([0-9]|10)\b/,
        "Please Enter Valid Rating"
      ).required("Please Enter Your Rating"),
      walletBalance: Yup.string().required("Please Enter Your Wallet Balance"),
      joiningDate: Yup.string().required("Please Enter Your Joining Date"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedCustomers = customers.map((cust) =>
          cust.id === customer.id ? { ...customer, ...values } : cust
        );
        setCustomers(updatedCustomers);
      } else {
        const newCustomer = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          ...values,
        };
        setCustomers([...customers, newCustomer]);
      }
      toggle();
      validation.resetForm();
    },
  });

  const onClickAction = async (customer, type) => {
    
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/admin/teacher/active-deactive/${customer._id}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        'Content-Type': 'application/json' // Ensure Content-Type is application/json
      },
      data: JSON.stringify({ isActive: type }) // Convert data to JSON string
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        
        const updatedCustomer = response.data.data;
        const updatedCustomers = customers.map((cust) => 
          cust._id === updatedCustomer._id ? { ...cust, isActive: type, isVerified: type } : cust
        );
        setSwalProps({ show: false });
        setTimeout(() => {
          setSwalProps({
              icon: "success",
              show: true,
              title: `Teacher ${type ? 'activate' : 'Inactivate'} Successfully.!!`,
              text: '',
              confirmButtonText: 'OK',
              showCancelButton: false,
          });
        }, 100);
        setCustomers(updatedCustomers);
      }
    } catch (error) {
      console.error("There was an error updating the request:", error);
    }
  };

  const handleCustomerClick = (customer) => {
    setCustomer(customer);
    setIsEdit(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   //Cell: () => <input type="checkbox" className="form-check-input" />,
      //   Cell: ({ row }) => row.index + 1, // Displaying 1-based index
      // },
      {
        Header: 'First Name',
        accessor: 'first_name',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      // {
      //   Header: 'Is Active',
      //   accessor: 'isActive',
      //   filterable: true,
      //   Cell: (cellProps) => <Isverified {...cellProps} />,
      //   Filter: ({ column: { filterValue, setFilter } }) => (
      //     <select className="form-control"
      //       onChange={e => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
      //       value={filterValue || ''}
      //     >
      //       <option value="">All</option>
      //       <option value="true">Yes</option>
      //       <option value="false">No</option>
      //     </select>
      //   ),
      //   filterMethod: (filter, row) => {
      //     const filterValue = filter.value === 'true';
      //     return row[filter.id] === filterValue;
      //   },
      // },
      {
        Header: 'Is Active',
        accessor: 'isActive',
        filterable: true,
        Cell: (cellProps) => <Isverified {...cellProps} />,
        Filter: ({ column: { filterValue, setFilter } }) => (
          <select className="form-control"
            onChange={e => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
            value={filterValue || ''}
          >
            <option value="">Show All</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        ),
      },
      {
        Header: 'Is Verified',
        accessor: 'isVerified',
        filterable: true,
        Cell: (cellProps) => <Isverified {...cellProps} />,
        Filter: ({ column: { filterValue, setFilter } }) => (
          <select className="form-control"
            onChange={e => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
            value={filterValue || ''}
          >
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        ),
        filterMethod: (filter, row) => {
          const filterValue = filter.value === 'true';
          return row[filter.id] === filterValue;
        },
      },
      {
        Header: 'Request Status',
        accessor: 'request_status',
        filterable: true,
        Cell: (cellProps) => <RequestStatus {...cellProps} />,
      },
      {
        Header: 'Rating',
        accessor: 'rating',
        filterable: true,
        Cell: (cellProps) => <Rating {...cellProps} />,
      },
      {
        Header: 'View Details',
        accessor: 'view',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Link
              to={`/teacher/details/${cellProps.row.original._id}`}
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              //onClick={() => handleCustomerClick(cellProps.row.original)}
            >
              View Details
            </Link>);
        }
      },
      // {
      //   Header: 'Action',
      //   Cell: (cellProps) => {
      //     const customerData = cellProps.row.original;
      
      //     return (
      //       <UncontrolledDropdown>
      //         <DropdownToggle tag="a" href="#" className="card-drop">
      //           <i className="mdi mdi-dots-horizontal font-size-18"></i>
      //         </DropdownToggle>
      //         <DropdownMenu className="dropdown-menu-end">
      //           <DropdownItem
      //             href="#"
      //             onClick={() => {
      //               onClickDelete(customerData);
      //             }}
      //           >
      //             <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
      //             Delete
      //             <UncontrolledTooltip placement="top" target="deletetooltip">
      //               Delete
      //             </UncontrolledTooltip>
      //           </DropdownItem>
      //           {customerData.isActive ? (
      //             <DropdownItem
      //               href="#"
      //               onClick={() => {
      //                 onClickAction(customerData, false);
      //               }}
      //             >
      //               <i className="mdi mdi-close font-size-16 text-danger me-1" id="inactive"></i>
      //               Inactive
      //               <UncontrolledTooltip placement="top" target="inactive">
      //                 Inactive
      //               </UncontrolledTooltip>
      //             </DropdownItem>
      //           ) : (
      //             <DropdownItem
      //               href="#"
      //               onClick={() => {
      //                 onClickAction(customerData, true);
      //               }}
      //             >
      //               <i className="mdi mdi-check font-size-16 text-success me-1" id="active"></i>
      //               Active
      //               <UncontrolledTooltip placement="top" target="active">
      //                 Active
      //               </UncontrolledTooltip>
      //             </DropdownItem>
      //           )}
      //         </DropdownMenu>
      //       </UncontrolledDropdown>
      //     );
      //   },
      // }
      {
        Header: 'Action',
        Cell: (cellProps) => (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem href="#" onClick={() => handleCustomerClick(cellProps.row.original)}>
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
              </DropdownItem> */}
              <DropdownItem
                href="#"
                onClick={() => {
                  onClickDelete(cellProps.row.original);
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                Delete
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </DropdownItem>
              {cellProps.row.original.isActive ? (
                <DropdownItem href="#" onClick={() => onClickAction(cellProps.row.original, false)}>
                  <i className="mdi mdi-close font-size-16 text-danger me-1"></i> Mark as Inactive
                </DropdownItem>
              ) : (
                <DropdownItem href="#" onClick={() => onClickAction(cellProps.row.original, true)}>
                  <i className="mdi mdi-check font-size-16 text-success me-1"></i> Mark as Active
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
      },
    ],
    [customers]
  );

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      setCustomer(null);
    }
  };

  const onClickDelete = (customer) => {
    setCustomer(customer);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = async () => {

    if (customer && customer._id) {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/teacher/${customer._id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
      try {
        const response = await axios.request(config);
        setSwalProps({ show: false });
        setSwalProps({
          icon: "success",
          show: true,
          title: 'Deleted Successfully.!!',
          text: '',
          confirmButtonText: 'OK', // Customize button text
          showCancelButton: false, // Hide cancel button
        });
        if(response.status == 200){
          const updatedCustomers = customers.filter(cust => cust._id !== customer._id);
          setCustomers(updatedCustomers);
          setDeleteModal(false);
        }
      } catch (error) {
        console.error("There was an error fetching the settings:", error);
      }
    }
  };

  useEffect(() => {

    // Assuming fetchCustomers is a function that fetches customer data from an API
    const fetchCustomers = async () => {
      //const data = await onGetCustomers();
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/teacher/get-all?limit=100&request_status=Approve&sort=createdAt&order=desc`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
  
      try {
        const response = await axios.request(config);
        
        const data = response.data.data.teachers;
        setCustomers(data);
      } catch (error) {
        console.error("There was an error fetching the settings:", error);
      }
    };
    fetchCustomers();
  }, []);

  const handleCustomerClicks = () => {
    setCustomer(null);
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <SweetAlert2 {...swalProps} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Approved Teachers" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={false}
                    isAddCustList={false}
                    handleCustomerClick={handleCustomerClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
};

export default EcommerceCustomers;

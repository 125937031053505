import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import axios from 'axios';
import moment from 'moment';
//i18n
import { withTranslation } from "react-i18next";
import { NotificationContext } from '../../../context/NotificationContext';
import { useLocation, useNavigate } from 'react-router-dom';

const NotificationDropdown = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const { openModelOnNotification } = useContext(NotificationContext);

  const fetchNotifications = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/notification/get/unread`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.data;
      setNotificationCount(data.length);
      setNotification(data);
    } catch (error) {
      console.error("There was an error fetching the notifications:", error);
    }
  };

  const markAsRead = async (notificationId) => {
    let config = {
      method: 'patch',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/notification/makeAsRead/${notificationId._id}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      await axios.request(config); 
      //await openModelOnNotification(notificationId.teacher_id._id);
      await fetchNotifications();
      navigate(`/teacher/details/${notificationId.teacher_id._id}`);
    } catch (error) {
      console.error("There was an error marking the notification as read:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{notificationCount}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/* <div className="col-auto">
                <Link to="/teacher/requests" className="small">
                  {" "}
                  View All
                </Link>
              </div> */}
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            
            {notification.map(notification => (
              <Link to={(location.pathname != '/teacher/requests') && '/teacher/requests' } className="text-reset notification-item" key={notification.teacher_id._id} onClick={() => markAsRead(notification)}>
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    
                  {notification?.teacher_id?.picture ? (
                    <img src={`${process.env.REACT_APP_BASE_URL}/api/user/image/${notification?.teacher_id?.picture}`} className="me-3 rounded-circle avatar-xs" alt="user-pic"></img>
                  ) : (
                    <span className="avatar-title bg-info rounded-circle font-size-16">
                      <i className="bx bx-badge-check" />
                    </span>
                  ) }
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {props.t('Requested for teacher')}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        { `${notification.teacher_id.first_name} ${notification.teacher_id.last_name}` }
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        { moment(notification.teacher_id.createdAt).format('DD-MM-YYYY hh:mm:ss') }{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/teacher/requests">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};

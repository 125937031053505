import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player";
//import images
import img1 from "../../assets/images/companies/img-1.png";
import img4 from "../../assets/images/companies/img-4.png";
import img5 from "../../assets/images/companies/img-5.png";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import './custom.css';
import { Modal, ModalHeader, ModalBody, Button, Label, Form, Input } from 'reactstrap';
import RequestModal from "../../components/Common/RequestModal";
import SweetAlert2 from 'react-sweetalert2';

const TeacherDetails = () => {
    const [swalProps, setSwalProps] = useState({});
    const { teacherId, requestId } = useParams();
    const [teacher, setTeacherDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const [videoUrl, setVideoUrl] = useState('');
    //const [customer, setCustomer] = useState(null);
    const [requestModal, setRequestModal] = useState(false);
    const [isActionType, setAction] = useState(null);
    const [rejectReasonModal, setRejectReasonModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    const handleVideoClick = () => {
        // Set the URL of the video to be played
        setVideoUrl(teacher?.youtube_video_link);
        toggleModal();
      };

    const fetchTeacherDetails = async () => {
        if (teacherId) {
            let config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/api/v1/teacher/${teacherId}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
                }
            };

            try {
                const response = await axios.request(config);
                const data = response.data.data;
                setTeacherDetails(data);
            } catch (error) {
                console.error("There was an error fetching the teacher details:", error);
            }
        }
    };

    useEffect(() => {  
        fetchTeacherDetails();
    }, [teacherId]);

    const onClickAction = (teacher, type) => {
        setTeacherDetails(teacher)
        setRequestModal(true);
        setAction(type)
      };

    const handleRejectAction = () => {
        setRequestModal(false);
        setRejectReasonModal(true);
    }

    const handleRequestAction = async () => {
        if (teacher && teacher?._id && requestId) {
          // Create the data object to be sent
          const data = {
            teacher_id: teacher._id,
            status: isActionType,
          };
      
          // Add reason if status is 'Reject'
          if (isActionType === 'Reject') {
            data.reason = rejectReason || 'fraud account'; // Use the reject reason from state or default to 'fraud account'
          }
      
          const config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/api/v1/request/${requestId}`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
              'Content-Type': 'application/json' // Ensure Content-Type is application/json
            },
            data: JSON.stringify(data) // Convert data to JSON string
          };
      
          try {
            const response = await axios.request(config);
            
            if (response.status === 200) {
              setSwalProps({});
              setTimeout(() => {
                setSwalProps({
                  icon: "success",
                  show: true,
                  title: 'Updated Successfully.!!',
                  text: '',
                  confirmButtonText: 'OK',
                  showCancelButton: false,
                });
              }, 100);
      
              setRequestModal(false);
              setRejectReasonModal(false);
              setRejectReason('');
              fetchTeacherDetails();
            }
          } catch (error) {
            console.error("There was an error updating the request:", error);
          }
        }
      };
      const handleRejectReasonSubmit = () => {
        if (rejectReason.trim()) {
          handleRequestAction();
        }
      };

    document.title = "Candidate Overview | Quippy  - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <div className="page-content">
            <SweetAlert2 {...swalProps} />
                <Modal isOpen={rejectReasonModal} toggle={() => setRejectReasonModal(!rejectReasonModal)}>
                    <ModalHeader toggle={() => setRejectReasonModal(!rejectReasonModal)}>
                    Enter Reject Reason
                    </ModalHeader>
                    <ModalBody>
                    <Form>
                        <Label for="rejectReason">Reason</Label>
                        <Input
                        type="textarea"
                        id="rejectReason"
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                        />
                        <Button color="primary" onClick={handleRejectReasonSubmit} className="mt-3">
                        Submit
                        </Button>
                    </Form>
                    </ModalBody>
                </Modal>
            <RequestModal
                show={requestModal}
                onDeleteClick={ isActionType === 'Reject' ? handleRejectAction : handleRequestAction}
                onCloseClick={() => setRequestModal(false)}
            />
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card className="mx-n4 mt-n4 bg-info bg-soft">
                                <CardBody>
                                    <div className="text-center mb-4">
                                    
                                    
                                        <img src={`${process.env.REACT_APP_BASE_URL}/api/user/image/${teacher?.picture}`} alt="" className="avatar-xl rounded-circle mx-auto d-block" />
                                        <h5 className="mt-3 mb-1">{`${teacher?.first_name ?? ''} ${teacher?.last_name ?? ''}`}</h5>
                                        <p className="text-muted mb-3"><strong>Status: </strong> {teacher?.request_status}</p>
                                        <div className="mx-auto">
                                            {teacher?.teaching_experience.map((data, key) => (
                                                <span className="badge me-1 text-bg-info" key={key}>{data}</span>    
                                            ))}
                                            {/* <span className="badge me-1 text-bg-info">Freelance</span>
                                            <span className="badge me-1 text-bg-success">Active</span>
                                            <span className="badge me-1 text-bg-warning">Adobe XD</span>
                                            <span className="badge me-1 text-bg-warning">Figma</span>
                                            <span className="badge text-bg-warning">Sketch</span> */}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    
                                        <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1">
                                            {teacher?.address && (
                                                <li>
                                                    <i className="bx bx-map align-middle"></i> {`${teacher?.address?.city}, ${teacher?.address?.state}, ${teacher?.address?.country}`}
                                                </li>
                                            )}
                                            <li>
                                                <i className="bx bx-money align-middle"></i> ${teacher?.pricing} / hrs
                                            </li>
                                            <li>
                                                <i className="bx bx-time align-middle"></i> {teacher?.availability}
                                            </li>
                                        </ul>
                                        { teacher?.youtube_video_link && (<div className="hstack gap-2">
                                            <button onClick={handleVideoClick} type="button" className="btn btn-primary"> <i className="bx bxl-youtube bx-md align-middle me-1"></i> YouTube Video</button>
                                            {/* <button type="button" className="btn btn-light"><i className='bx bx-bookmark align-baseline'></i></button> */}
                                        </div> )}
                                        <Modal isOpen={modalOpen} toggle={toggleModal}>
                                            <ModalHeader toggle={toggleModal}>
                                            YouTube Video
                                            </ModalHeader>
                                            <ModalBody>
                                            <Label for="videoPlayer">Video</Label>
                                            <ReactPlayer 
                                                url={videoUrl}
                                                controls
                                                width='auto'
                                                //height='auto'
                                            />
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={3}>
                            <Card>
                                <CardBody>
                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-buildings font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Company Name:</h6>
                                                    <span className="text-muted">{teacher?.company_name}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-calendar-event font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Date Of Birth:</h6>
                                                    <span className="text-muted">{teacher?.date_of_birth}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-male font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Gender:</h6>
                                                    {teacher?.gender}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-phone-call font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Mobile Number:</h6>
                                                    <span className="text-muted">{teacher?.contact_details?.mobile_number}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-globe font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Website:</h6>
                                                    <span className="text-muted">{teacher?.contact_details?.website}</span>
                                                </div>
                                            </div>
                                        </li>
                                        
                                        <li>
                                            <div className="d-flex">
                                                <i className='mdi mdi-google-translate font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Languages:</h6>
                                                    {/* <span className="text-muted">English, France</span> */}
                                                    <div className="mx-auto">
                                                        {teacher?.language_skills && (
                                                            <li>
                                                                <i className="bx bx-language align-middle"></i> 
                                                                {teacher.language_skills.map((language, index) => (
                                                                    <span className="badge me-1 text-bg-info" key={index}>
                                                                        {language.language} - {language.dialect}
                                                                    </span>
                                                                ))}
                                                            </li>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                         
                                        {teacher?.request_status === 'Pending' && (
                                            <li className="hstack gap-2 mt-3">
                                                <Link to="" onClick={() => { onClickAction(teacher, 'Approve') }} className="btn btn-soft-primary w-100">Approve</Link>
                                                <Link to="" onClick={() => { onClickAction(teacher, 'Reject') }} className="btn btn-soft-danger w-100">Reject</Link>
                                            </li>
                                        )}
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* About us */}
                        <Col lg={9}>
                            <Card>
                                <CardBody>
                                    <h5 className="mb-3">Introduction Video</h5>
                                    
                                    { teacher?.intro_video && (<ReactPlayer
                                    url={`${process.env.REACT_APP_MEDIA_URL}/user/video/` + teacher?.intro_video}
                                    playing={false}
                                    controls={true}
                                    width="70%"
                                    height="70%"
                                    />)}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className="mt-3 mb-3">Education</h5>
                                        
                                    <ul className="verti-timeline list-unstyled">
                                        {teacher?.education?.map((d, index) => (
                                        <li className="event-list" key={index}>
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle"></i>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <h6 className="font-size-14 mb-1">Degree - {d?.title}</h6>
                                                        <p className="text-muted"><strong>Institution :</strong> {d?.tags?.join(", ")}</p>
                                                        
                                                        <p className="text-muted mb-0"> <strong>Description :</strong> {d?.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        ))}
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className="mt-3 mb-3">Work Experience</h5>
                                        
                                    <ul className="verti-timeline list-unstyled">
                                        {teacher?.work_experience?.map((d, index) => (
                                        <li className="event-list" key={index}>
                                            
                                            <div className="event-timeline-dot">
                                                <i className="bx bx-right-arrow-circle"></i>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <h6 className="font-size-14 mb-1">Position - {d?.position}</h6>
                                                        <p className="text-muted"><strong>Company :</strong> {d?.company_name?.join(", ")}</p>
                                                        
                                                        <p className="text-muted mb-0"> <strong>Details :</strong> {d?.details}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        ))}
                                    </ul>
                                </CardBody>
                            </Card>
                            
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <h5 className="mb-3">Social Media</h5>
                                            <div className="hstack gap-2">
                                                { teacher?.contact_details?.whatsapp && (<div className="tooltip-container">
                                                    <Link to="" className="btn btn-soft-success">
                                                        <i className="bx bxl-whatsapp align-middle me-1"></i> Whatsapp
                                                    </Link>
                                                    <div className="tooltip">
                                                        <img src={`${process.env.REACT_APP_BASE_URL}/api/user/QR/${teacher?.contact_details?.whatsapp}`} alt="Whatsapp" />
                                                    </div>
                                                </div>) }
                                                { teacher?.contact_details?.instagram && (<div className="tooltip-container">
                                                    <Link to="" className="btn btn-soft-pink">
                                                        <i className="bx bxl-instagram align-middle me-1"></i> Instagram
                                                    </Link>
                                                    <div className="tooltip">
                                                        <img src={`${process.env.REACT_APP_BASE_URL}/api/user/QR/${teacher?.contact_details?.instagram}`} alt="Instagram" />
                                                    </div>
                                                </div>) }
                                                { teacher?.contact_details?.line && (<div className="tooltip-container">
                                                    <Link to="" className="btn btn-soft-info">
                                                        <i className="bx bxl-line align-middle me-1"></i> Line
                                                    </Link>
                                                    <div className="tooltip">
                                                        <img src={`${process.env.REACT_APP_BASE_URL}/api/user/QR/${teacher?.contact_details?.line}`} alt="Line" />
                                                    </div>
                                                </div>) }
                                                { teacher?.contact_details?.wechat && (<div className="tooltip-container">
                                                    <Link to="" className="btn btn-soft-info">
                                                        <i className="bx bxl-line align-middle me-1"></i> Wechat
                                                    </Link>
                                                    <div className="tooltip">
                                                        <img src={`${process.env.REACT_APP_BASE_URL}/api/user/QR/${teacher?.contact_details?.wechat}`} alt="Wine" />
                                                    </div>
                                                </div>) }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default TeacherDetails;

import React, { createContext, useState } from 'react';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [teacherId, setTeacherId] = useState(null);

  const openModelOnNotification = (id) => {
    setTeacherId(id);
  };

  return (
    <NotificationContext.Provider value={{ teacherId, openModelOnNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../components/Common/DeleteModal";
import SweetAlert2 from 'react-sweetalert2';
import ReactPlayer from "react-player";

const EcommerceCustomers = () => {
  const [rows1, setrows1] = useState([{ id: 1, question: "" }]);
  const [details, setDetails] = useState("");
  const [supplementDAta, setSupplements] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/supplement`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.data;
      if (data) {
        setSupplements(data);
        setDetails(data.detail);
        setrows1(data.questions.map((question, index) => ({ id: index + 1, question })));
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      setLoading(false);
    }
  };

  const handleAddRowNested = () => {
    const newId = rows1.length > 0 ? rows1[rows1.length - 1].id + 1 : 1;
    setrows1([...rows1, { id: newId, question: "" }]);
  };

  const handleRemoveRow = (id) => {
    if (id !== 1) {
      setrows1(rows1.filter((x) => x.id !== id));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "logo") {
      setFile(e.target.files[0]);
    } else if (name === "details") {
      setDetails(value);
    } else {
      const id = parseInt(name);
      const newRows = rows1.map((row) =>
        row.id === id ? { ...row, question: value } : row
      );
      setrows1(newRows);
    }
  };

  const validateForm = () => {
    const errors = {};
    // if (!details) {
    //   errors.details = "Details are required";
    // }
    if (!file && !supplementDAta) {
      errors.file = "File is required";
    }
    setErrors(errors);
    setLoading(false);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("detail", details);
    if (file) {
      formData.append("file", file);
    }
    rows1.forEach((row, index) => {
      formData.append(`questions[${index}]`, row.question);
    });

    try {
      let config = {
        method: supplementDAta ? "PATCH" : "POST",
        maxBodyLength: Infinity,
        url: supplementDAta
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/supplement/${supplementDAta._id}`
          : `${process.env.REACT_APP_BASE_URL}/api/v1/supplement`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        },
        data: formData,
      };

      const response = await axios.request(config);
      if (response.status === 200 || response.status === 201) {
        toastr.success(response.data.message, 'Success');
        fetchData();
      } else {
        toastr.error(response.data.message, 'Error');
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      setLoading(false);
    }
  };

  const onClickDelete = () => {
    setDeleteModal(true);
  };

  const handleDeleteCustomer = async () => {
    if (supplementDAta && supplementDAta._id) {
      let config = {
        method: 'DELETE',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/supplement/${supplementDAta._id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
      try {
        const response = await axios.request(config);
        setSwalProps({
          icon: "success",
          show: true,
          title: 'Deleted Successfully!',
          confirmButtonText: 'OK',
        });
        if (response.status === 200) {
          setDetails("");
          setSupplements("");
          setFile(null);
          setrows1([{ id: 1, question: "" }]);
          setDeleteModal(false);
        }
      } catch (error) {
        console.error("There was an error deleting the data:", error);
      }
    }
  };

  const renderMedia = (file) => {
    if (!file) return null;
    const fileExtension = file.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isVideo = ['mp4', 'avi', 'mov', 'wmv'].includes(fileExtension);

    if (isImage) {
      return (
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}/supplement/${file}`}
          alt="Supplemental"
          style={{ maxWidth: '20%', height: 'auto', paddingTop: '10px' }}
        />
      );
    }

    if (isVideo) {
      return (
        <ReactPlayer
          url={`${process.env.REACT_APP_MEDIA_URL}/supplement/${file}`}
          playing={false}
          controls={true}
          width="40%"
          height='auto'
          style={{ maxWidth: '20%', height: 'auto', paddingTop: '10px' }}
        />
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <SweetAlert2 {...swalProps} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Supplemental Questions" />

          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h6 className="mb-4 card-title">Supplemental Questions</h6>
                    {supplementDAta?._id && (
                      <Button type="button" color="danger" onClick={onClickDelete}>
                        Delete
                      </Button>
                    )}
                  </div>
                  <Form className="outer-repeater" onSubmit={handleSubmit}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <div className="mb-3">
                          <Label htmlFor="formname">Upload File :</Label>
                          <Input
                            type="file"
                            name="logo"
                            onChange={handleInputChange}
                          />
                          {errors.file && <div className="text-danger">{errors.file}</div>}
                          {renderMedia(supplementDAta?.file)}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formmessage">Details :</Label>
                          <Input
                            type="textarea"
                            id="formmessage"
                            className="form-control"
                            rows="3"
                            placeholder="Enter Details"
                            name="details"
                            value={details}
                            onChange={handleInputChange}
                          />
                          {/* {errors.details && <div className="text-danger">{errors.details}</div>} */}
                        </div>

                        <div className="inner-repeater mb-4">
                          <Label>Questions :</Label>
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {rows1.map((formRow, key) => (
                                <tr key={formRow.id}>
                                  <td>
                                    <Row className="mb-2">
                                      <Col md="10">
                                        <Input
                                          type="text"
                                          className="inner form-control"
                                          placeholder={`Enter Question ${key + 1}`}
                                          name={`${formRow.id}`}
                                          value={formRow.question}
                                          onChange={handleInputChange}
                                        />
                                      </Col>
                                      { key > 0 && (<Col md="2">
                                        <Button
                                          color="primary"
                                          className="btn-block inner"
                                          style={{ width: "100%" }}
                                          onClick={() => handleRemoveRow(formRow.id)}
                                        >
                                          Delete
                                        </Button>
                                      </Col>)}
                                    </Row>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <Button
                            type="button"
                            onClick={handleAddRowNested}
                            color="success"
                            className="mt-1"
                          >
                            Add Question
                          </Button>
                        </div>

                        <Button type="submit" color="primary" disabled={loading}>
                          {loading ? (
                            <>
                              <i className="fa fa-spinner fa-spin"></i> Loading...
                            </>
                          ) : supplementDAta?._id ? 'Update' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
};

export default EcommerceCustomers;

import React, { useEffect, useState, useMemo, useContext } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import axios from 'axios';
// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import RequestModal from "../../components/Common/RequestModal";
import TableContainer from '../../components/Common/TableContainer';

// Column
import {
  Isverified,
  UserName,
  PhoneEmail,
  Address,
  Rating,
  WalletBalances,
  JoiningDate,
  RequestStatus
} from '../Ecommerce/EcommerceCustomers/EcommerceCustCol';
import SweetAlert2 from 'react-sweetalert2';

import { NotificationContext } from '../../context/NotificationContext';

const requests = () => {
  const [swalProps, setSwalProps] = useState({});
  // meta title
  document.title = "Customers | Quippy  - React Admin & Dashboard Template";

  const [modal, setModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [isActionType, setAction] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const { teacherId } = useContext(NotificationContext);


  useEffect(() => {
    if (teacherId) {
      openModelOnNotification(teacherId);
    }
  }, [teacherId]);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: (customer && customer.username) || '',
      phone: (customer && customer.phone) || '',
      email: (customer && customer.email) || '',
      address: (customer && customer.address) || '',
      rating: (customer && customer.rating) || '',
      walletBalance: (customer && customer.walletBalance) || '',
      joiningDate: (customer && customer.joiningDate) || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),
      rating: Yup.string().matches(
        /\b([0-9]|10)\b/,
        "Please Enter Valid Rating"
      ).required("Please Enter Your Rating"),
      walletBalance: Yup.string().required("Please Enter Your Wallet Balance"),
      joiningDate: Yup.string().required("Please Enter Your Joining Date"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedCustomers = customers.map((cust) =>
          cust.id === customer.id ? { ...customer, ...values } : cust
        );
        setCustomers(updatedCustomers);
      } else {
        const newCustomer = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          ...values,
        };
        setCustomers([...customers, newCustomer]);
      }
      toggle();
      validation.resetForm();
    },
  });

  const openModelOnNotification = async (teacherId) => {
    if(teacherId){
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/teacher/${teacherId}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
  
      try {
        const response = await axios.request(config); 
        const data = response.data.data;
        setTeacher(data);
        setIsEdit(true);
        toggle();
      } catch (error) {
        console.error("There was an error fetching the teacher details:", error);
      }
    }
  };

  const handleCustomerClick = (customer) => {
    // openModelOnNotification(customer.teacher_id._id)
    // setIsEdit(true);
    // toggle();
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   //Cell: () => <input type="checkbox" className="form-check-input" />,
      //   Cell: ({ row }) => row.index + 1, // Displaying 1-based index
      // },
      {
        Header: 'First Name',
        accessor: 'teacher_id.first_name',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Last Name',
        accessor: 'teacher_id.last_name',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Email',
        accessor: 'teacher_id.email',
        filterable: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: 'Is Verified',
        accessor: 'teacher_id.isVerified',
        filterable: true,
        Cell: (cellProps) => <Isverified {...cellProps} />,
        Filter: ({ column: { filterValue, setFilter } }) => (
          <select className="form-control"
            onChange={e => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
            value={filterValue || ''}
          >
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        ),
        filterMethod: (filter, row) => {
          const filterValue = filter.value === 'true';
          return row[filter.id] === filterValue;
        },
      },
      {
        Header: 'Request Status',
        accessor: 'teacher_id.request_status',
        filterable: true,
        Cell: (cellProps) => <RequestStatus {...cellProps} />,
      },
      // {
      //   Header: 'Rating',
      //   accessor: 'teacher_id.rating',
      //   filterable: true,
      //   Cell: (cellProps) => <Rating {...cellProps} />,
      // },
      {
        Header: 'View Details',
        accessor: 'view',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Link
              to={`/teacher/details/${cellProps.row.original.teacher_id._id}/${cellProps.row.original._id}`}
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              //onClick={() => handleCustomerClick(cellProps.row.original)}
            >
              View Details
            </Link>);
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          const customerData = cellProps.row.original;
          const isApproveVisible = customerData?.teacher_id?.request_status === 'Reject' || customerData?.teacher_id?.request_status === 'Pending';
          const isRejectVisible = customerData?.teacher_id?.request_status === 'Approve' || customerData?.teacher_id?.request_status === 'Pending';
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    onClickDelete(customerData);
                  }}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
                {isApproveVisible && (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickAction(customerData, 'Approve');
                    }}
                  >
                    <i className="mdi mdi-check font-size-16 text-success me-1" id="approved" ></i>
                    Approve
                    <UncontrolledTooltip placement="top" target="approved">
                      Approve
                    </UncontrolledTooltip>
                  </DropdownItem>
                )}
                {isRejectVisible && (<DropdownItem
                  href="#"
                  onClick={() => {
                    onClickAction(customerData, 'Reject');
                  }}
                >
                  <i className="mdi mdi-close font-size-16 text-danger me-1" id="Reject"></i>
                  Reject
                  <UncontrolledTooltip placement="top" target="Reject">
                    Reject
                  </UncontrolledTooltip>
                </DropdownItem>)}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      setCustomer(null);
    }
  };

  const onClickAction = (customer, type) => {
    setCustomer(customer);
    setRequestModal(true);
    setAction(type)
  };

  const onClickDelete = (customer) => {
    setCustomer(customer);
    setDeleteModal(true);
  };

  const handleRejectAction = () => {
    setRequestModal(false);
    setRejectReasonModal(true);
  }

  const handleRequestAction = async () => {
    if (customer && customer?._id && customer?.teacher_id?._id) {
      // Create the data object to be sent
      const data = {
        teacher_id: customer.teacher_id._id,
        status: isActionType,
      };
  
      // Add reason if status is 'Reject'
      if (isActionType === 'Reject') {
        data.reason = rejectReason || 'fraud account'; // Use the reject reason from state or default to 'fraud account'
      }
  
      const config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/request/${customer._id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
          'Content-Type': 'application/json' // Ensure Content-Type is application/json
        },
        data: JSON.stringify(data) // Convert data to JSON string
      };
  
      try {
        const response = await axios.request(config);
        
        if (response.status === 200) {
          setSwalProps({ show: false });
          setTimeout(() => {
            setSwalProps({
              icon: "success",
              show: true,
              title: 'Updated Successfully.!!',
              text: '',
              confirmButtonText: 'OK',
              showCancelButton: false,
            });
          }, 100);
  
          const updatedCustomers = customers.filter(cust => cust._id !== customer._id);
          setCustomers(updatedCustomers);
          fetchCustomers();
          setRequestModal(false);
          setRejectReasonModal(false);
          setRejectReason('');
        }
      } catch (error) {
        console.error("There was an error updating the request:", error);
      }
    }
  };
  

  const handleRejectReasonSubmit = () => {
    if (rejectReason.trim()) {
      handleRequestAction();
    }
  };

  const handleDeleteCustomer = async () => {

    if (customer && customer._id) {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/request/${customer._id}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
        }
      };
      try {
        const response = await axios.request(config);
        console.log("response > > > >",response);
        setSwalProps({ show: false });
        setSwalProps({
          icon: "success",
          show: true,
          title: 'Deleted Successfully.!!',
          text: '',
          confirmButtonText: 'OK', // Customize button text
          showCancelButton: false, // Hide cancel button
        });
        if(response.status == 200){
          const updatedCustomers = customers.filter(cust => cust._id !== customer._id);
          setCustomers(updatedCustomers);
          setDeleteModal(false);
        }
      } catch (error) {
        console.error("There was an error fetching the settings:", error);
      }
    }
  };

  // Assuming fetchCustomers is a function that fetches customer data from an API
  const fetchCustomers = async () => {
    //const data = await onGetCustomers();
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/request?limit=100&request_status=Pending&sort=createdAt&order=desc`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("authUser")}`,
      }
    };

    try {
      const response = await axios.request(config);
      const data = response.data.data.request;
      setCustomers(data);
    } catch (error) {
      console.error("There was an error fetching the settings:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleCustomerClicks = () => {
    setCustomer(null);
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <SweetAlert2 {...swalProps} />
      <Modal isOpen={rejectReasonModal} toggle={() => setRejectReasonModal(!rejectReasonModal)}>
        <ModalHeader toggle={() => setRejectReasonModal(!rejectReasonModal)}>
          Enter Reject Reason
        </ModalHeader>
        <ModalBody>
          <Form>
            <Label for="rejectReason">Reason</Label>
            <Input
              type="textarea"
              id="rejectReason"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
            <Button color="primary" onClick={handleRejectReasonSubmit} className="mt-3">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <RequestModal
        show={requestModal}
        onDeleteClick={ isActionType === 'Reject' ? handleRejectAction : handleRequestAction}
        onCloseClick={() => setRequestModal(false)}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Teacher Requests" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={false}
                    isAddCustList={false}
                    handleCustomerClick={handleCustomerClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {isEdit ? "View Teacher Details" : "Add Customer"}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label h5">First Name</Label>
                            <p className="h6">{teacher?.first_name}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Last Name</Label>
                            <p className="h6">{teacher?.last_name}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Email</Label>
                            <p className="h6">{teacher?.email}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Address</Label>
                            <p className="h6">
                              {teacher?.address.city}, {teacher?.address.state}, {teacher?.address.country}
                            </p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Rating</Label>
                            <p className="h6">{teacher?.rating}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Pricing</Label>
                            <p className="h6">{teacher?.pricing}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Joining Date</Label>
                            <p className="h6">{new Date(teacher?.createdAt).toLocaleDateString()}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Request Status</Label>
                            <p className="h6">{teacher?.request_status}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Location</Label>
                            <p className="h6">Coordinates: {teacher?.location.coordinates.join(", ")}</p>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label h5">Languages</Label>
                            <p className="h6">{teacher?.language_skills.map(skill => skill.language).join(", ")}</p>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default requests;
